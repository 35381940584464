/**general margin */
.margin-xxs {
  margin: 4px;
}

.margin-xs {
  margin: 8px;
}

.margin-sm {
  margin: 12px;
}

.margin-md {
  margin: 16px;
}

.margin-lg {
  margin: 24px;
}

.margin-xl {
  margin: 32px;
}

.margin-xxl {
  margin: 40px;
}

/*---------------------------*/

/**margin horizontal */

.h-margin-xxs {
  margin: 0 4px;
}

.h-margin-xs {
  margin: 0 8px;
}

.h-margin-sm {
  margin: 0 12px;
}

.h-margin-md {
  margin: 0 16px;
}

.h-margin-lg {
  margin: 0 24px;
}

.h-margin-xl {
  margin: 0 32px;
}

.h-margin-xxl {
  margin: 0 40px;
}

/*---------------------------*/

/**margin vertical */

.v-margin-xxs {
  margin: 4px 0;
}

.v-margin-xs {
  margin: 8px 0;
}

.v-margin-sm {
  margin: 12px 0;
}

.v-margin-md {
  margin: 16px 0;
}

.v-margin-lg {
  margin: 24px 0;
}

.v-margin-xl {
  margin: 32px 0;
}

.v-margin-xxl {
  margin: 40px 0;
}

/*---------------------------*/

/**margin bottom */

.margin-bottom-xxs {
  margin-bottom: 4px;
}

.margin-bottom-xs {
  margin-bottom: 8px;
}

.margin-bottom-sm {
  margin-bottom: 12px;
}

.margin-bottom-md {
  margin-bottom: 16px;
}

.margin-bottom-lg {
  margin-bottom: 24px;
}

.margin-bottom-xl {
  margin-bottom: 32px;
}

.margin-bottom-xxl {
  margin-bottom: 40px;
}

/*---------------------------*/

/**margin top */

.margin-top-xxs {
  margin-top: 4px;
}

.margin-top-xs {
  margin-top: 8px;
}

.margin-top-sm {
  margin-top: 12px;
}

.margin-top-md {
  margin-top: 16px;
}

.margin-top-lg {
  margin-top: 24px;
}

.margin-top-xl {
  margin-top: 32px;
}

.margin-top-xxl {
  margin-top: 40px;
}

/*---------------------------*/

/**margin right */

.margin-right-xxs {
  margin-right: 4px;
}

.margin-right-xs {
  margin-right: 8px;
}

.margin-right-sm {
  margin-right: 12px;
}

.margin-right-md {
  margin-right: 16px;
}

.margin-right-lg {
  margin-right: 24px;
}

.margin-right-xl {
  margin-right: 32px;
}

.margin-right-xxl {
  margin-right: 40px;
}

/*---------------------------*/

/**margin left */

.margin-left-xxs {
  margin-left: 4px;
}

.margin-left-xs {
  margin-left: 8px;
}

.margin-left-sm {
  margin-left: 12px;
}

.margin-left-md {
  margin-left: 16px;
}

.margin-left-lg {
  margin-left: 24px;
}

.margin-left-xl {
  margin-left: 32px;
}

.margin-left-xxl {
  margin-left: 40px;
}

/*---------------------------*/
