/**general padding */
.padding-xxs {
  padding: 4px;
}

.padding-xs {
  padding: 8px;
}

.padding-sm {
  padding: 12px;
}

.padding-md {
  padding: 16px;
}

.padding-lg {
  padding: 24px;
}

.padding-xl {
  padding: 32px;
}

.padding-xxl {
  padding: 40px;
}

/*---------------------------*/

/**padding horizontal */

.h-padding-xxs {
  padding: 0 4px;
}

.h-padding-xs {
  padding: 0 8px;
}

.h-padding-sm {
  padding: 0 12px;
}

.h-padding-md {
  padding: 0 16px;
}

.h-padding-lg {
  padding: 0 24px;
}

.h-padding-xl {
  padding: 0 32px;
}

.h-padding-xxl {
  padding: 0 40px;
}

/*---------------------------*/

/**padding vertical */

.v-padding-xxs {
  padding: 4px 0;
}

.v-padding-xs {
  padding: 8px 0;
}

.v-padding-sm {
  padding: 12px 0;
}

.v-padding-md {
  padding: 16px 0;
}

.v-padding-lg {
  padding: 24px 0;
}

.v-padding-xl {
  padding: 32px 0;
}

.v-padding-xxl {
  padding: 40px 0;
}

/*---------------------------*/

/**padding bottom */

.padding-bottom-xxs {
  padding-bottom: 4px;
}

.padding-bottom-xs {
  padding-bottom: 8px;
}

.padding-bottom-sm {
  padding-bottom: 12px;
}

.padding-bottom-md {
  padding-bottom: 16px;
}

.padding-bottom-lg {
  padding-bottom: 24px;
}

.padding-bottom-xl {
  padding-bottom: 32px;
}

.padding-bottom-xxl {
  padding-bottom: 40px;
}

/*---------------------------*/

/**padding top */

.padding-top-xxs {
  padding-top: 4px;
}

.padding-top-xs {
  padding-top: 8px;
}

.padding-top-sm {
  padding-top: 12px;
}

.padding-top-md {
  padding-top: 16px;
}

.padding-top-lg {
  padding-top: 24px;
}

.padding-top-xl {
  padding-top: 32px;
}

.padding-top-xxl {
  padding-top: 40px;
}

/*---------------------------*/

/**padding right */

.padding-right-xxs {
  padding-right: 4px;
}

.padding-right-xs {
  padding-right: 8px;
}

.padding-right-sm {
  padding-right: 12px;
}

.padding-right-md {
  padding-right: 16px;
}

.padding-right-lg {
  padding-right: 24px;
}

.padding-right-xl {
  padding-right: 32px;
}

.padding-right-xxl {
  padding-right: 40px;
}

/*---------------------------*/

/**padding left */

.padding-left-xxs {
  padding-left: 4px;
}

.padding-left-xs {
  padding-left: 8px;
}

.padding-left-sm {
  padding-left: 12px;
}

.padding-left-md {
  padding-left: 16px;
}

.padding-left-lg {
  padding-left: 24px;
}

.padding-left-xl {
  padding-left: 32px;
}

.padding-left-xxl {
  padding-left: 40px;
}

/*---------------------------*/
